<template>
	<Toast />
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="images/Fybr_logo.png" alt="Fybr logo" class="mb-5" style="height:60px;">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">Parking Manager</div>
                    </div>
                    <form @submit.prevent="handleSubmit()" class="w-full md:w-10 mx-auto">
                        <label for="username" :class="{'p-error':v$.username.$invalid && submitted, 'block': true, 'font-medium':true, 'text-xl':true, 'mb-2':true}">Username</label>
                        <InputText id="useraname" v-model="v$.username.$model" type="text" :class="{'p-invalid':v$.username.$invalid && submitted, 'w-full':true, 'mb-3':true}" placeholder="Username" style="padding:1rem;" />
                
                        <label for="password1" :class="{'p-error':v$.password.$invalid && submitted, 'block': true, 'font-medium':true, 'text-xl':true, 'mb-2':true}">Password</label>
                        <Password id="password1" v-model="v$.password.$model" placeholder="Password" :toggleMask="true" :feedback="false" :class="{'p-invalid':v$.password.$invalid && submitted, 'w-full':true, 'mb-3':true}" inputClass="w-full" inputStyle="padding:1rem" ></Password>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                            <div class="flex align-items-center">
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" onclick="alert('not implemented');" style="color: var(--primary-color)">Forgot password?</a>
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl" type="submit"></Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch, reactive, onMounted } from 'vue';
import { useApi } from '@/composables/useApi';
import { userLogin } from '@/api/interface';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { decodeJwt } from 'jose';

export default {
	name: 'Login',

	setup() {
		const state = reactive({
			username: '',
			password: ''
		});
		const toast = useToast();
		const { api_error, callApi } = useApi();
		const submitted = ref(false);
		const rules = {
			username: { required, email },
			password: { required }
		};
		const v$ = useVuelidate(rules, state);
		const router = useRouter();
		const store = useStore();

		const handleSubmit = async () => {
			submitted.value = true;

			const isFormValid = await v$.value.$validate();
			if (isFormValid) {
				await handleLogin();
			} else {
				v$.value.$errors.forEach(err => {
					toast.add({severity: 'error', summary: err.$property + ' - validation error', detail: err.$message, life:5000});
				});
			}
		}

		const handleLogin = async () => {
			await callApi(userLogin(state), responseData => {
				localStorage.setItem('jwt', responseData.jwt);
				const { display_name } = decodeJwt(responseData.jwt);
				router.push({ name: 'dashboard'});
				store.commit('push_toast', {severity: 'success', summary: 'Login Success', detail: 'Welcome ' + display_name, life: 3000});
			});
		}

		const logoColor = computed(() => {
			if (this.$appState.darkTheme) return 'white';
			return 'dark';
		});

		watch(api_error, error => {
			if(error){
				toast.add({severity: 'error', summary: 'Login Failure', detail: 'Invalid credentials entered.', life: 3000});
			}
		});

		onMounted(() => {
			if(store.state.toastQueue.length) {
				toast.add(store.state.toastQueue.pop());
			}
		});

		return { state, handleSubmit, handleLogin, logoColor, v$, submitted }
	}
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>